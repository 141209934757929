<template>
  <sign-page
    title-text="会议"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :title-menus="[{key: 'add', label: '+创建会议'}]"
    @tableAction="tableAction"
    :table-actions="tableActions"
    :form-parms-update="formParmsUpdate"
    :deal-data="dealData"
    :deal-form-data="dealFormData"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  meetRequest as request
} from '@/api'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '标题',
          key: 'title',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '主持人',
          key: 'directUserId',
          selectDatas: this.$store.getters.userList
        },
        {
          type: 'multipleChoose',
          label: '参与人',
          key: 'userIds',
          selectDatas: this.$store.getters.userList
        },
        {
          type: 'datetimePicker',
          label: '计划开始时间',
          key: 'planTime'
        },
        {
          type: 'datetimePicker',
          label: '任务统计时间点',
          key: 'taskTime',
          check: {
            required: true
          }
        },
        {
          type: 'daterange',
          label: '统计周期',
          key: 'taskDateRange',
          check: {
            required: true
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    tableActions () {
      return [{
        key: 'go',
        label: '进入'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '标题',
          field: 'title'
        },
        {
          title: '主持人',
          field: 'directUserName'
        },
        {
          title: '参与人',
          field: 'userNames',
          width: 270
        },
        {
          title: '计划开始时间',
          width: 120,
          field: 'planTime',
          render: function (h, row) {
            return h('div', {}, row.planTime ? row.planTime.slice(5, 16) : '')
          }
        },
        {
          title: '统计周期',
          field: 'taskStartTime',
          render: function (h, row) {
            return h('div', {}, (row.taskStartTime ? row.taskStartTime.slice(0, 10) : '') + '至' + (row.taskEndTime ? row.taskEndTime.slice(5, 10) : ''))
          }
        }]
        return data
      }
    }
  },
  created () {
    this.$store.dispatch('loadUserList')
  },
  methods: {
    tableAction (data) {
      if (data.action === 'go') {
        this.$router.push({name: 'meet.detail', query: {
          id: data.data.id
        }})
      }
    },
    dealFormData (data) {
      if (data.taskDateRange && data.taskDateRange.length > 0) {
        data.taskStartTime = data.taskDateRange[0]
        data.taskEndTime = data.taskDateRange[0]
        if (data.taskDateRange.length === 2) {
          data.taskEndTime = data.taskDateRange[1]
        }
      }
      return data
    },
    async dealData (data) {
      let userMap = {}
      let userList = await this.$store.dispatch('loadUserList')
      userList.forEach((v) => {
        userMap[v.key] = v.label
      })
      data.forEach((v) => {
        v.directUserName = userMap[v.directUserId]
        v.taskDateRange = [v.taskStartTime, v.taskEndTime]
        v.userIds = v.userIds ? v.userIds.split(',').map(v2 => Number(v2)) : []
        v.userNames = v.userIds.map(v2 => userMap[v2]).join(',')
      })
      return data
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
